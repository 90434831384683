import React from "react"
import { graphql } from "gatsby";
import Image from "../components/Image"
import { ContentfulMedia } from "../components/Media"
import Link from "../components/Link"
import RichText, { RichTextCopyOnly } from "../components/RichText"

const HeroSplash = ({
	callToAction,
	internalLink,
	mediaLandscape,
	mediaPortrait,
	title,
	titles,
	backgroundColor,
	color
}) => {

	return (
		<section className={`hero ${backgroundColor ? 'has-background' : ''}`}>
			<div className={`hero-inner ${mediaPortrait ? 'hero-landscape' : ''}`}>
				{mediaLandscape?.map(asset => (
					<div className="prel ohide" key={asset.contentful_id}>
						<div className="object-cover">
							<ContentfulMedia image={asset} file={asset.file} size="hires" />
							{/* {mediaLandscape.length > 1 ? (
								<ContentfulMedia image={asset} file={asset.file} size="hires" />
							) : (
								<img src={asset?.file?.url} alt="Harrys London - Hero image" />
							)} */}
						</div>
					</div>
				))}
			</div>
			
			{mediaPortrait && (
				<div className="hero-inner hero-portrait">
					{mediaPortrait?.map(asset => (
						<div className="prel ohide" key={asset.contentful_id}>
							<div className="object-cover">
								<ContentfulMedia image={asset} file={asset.file} size="hires" />
							</div>
						</div>
					))}
				</div>
			)}
			
			{internalLink && (
				<div className={`hero-titles ${!backgroundColor ? 'innerx4 page-gutters' : ''}`}>
					<div style={{
						color: color || 'var(--white)',
						backgroundColor: backgroundColor || 'transparent'
					}}>
						{titles ? <RichTextCopyOnly content={titles} /> : <h1>{title}</h1>}
						<Link to={internalLink} className="cta mt"><h2>{callToAction}</h2></Link>
					</div>
				</div>
			)}
			
		</section>
	)
}

const HeroWithCopy = ({
	// callToAction,
	// internalLink,
	mediaLandscape,
	// mediaPortrait,
	title,
	backgroundColor,
	color,
	copy
}) => {

	return (
		<section className="hero has-copy" style={{ backgroundColor, color }}>
			<div className={`hero-inner`}>
				{mediaLandscape?.map(asset => (
					<div className="prel ohide hero-copy-image" key={asset.contentful_id}>
						<div className="object-cover">
							<ContentfulMedia image={asset} file={asset.file} size="hires" />
						</div>
					</div>
				))}
				<div className="flex-center">
					<div className="padx4">
						<h1 className="align-center mbx4">{title}</h1>
						<RichText content={copy} />
					</div>
				</div>
			</div>
			
			{/* {mediaPortrait && (
				<div className="fs flex-stretch hero-portrait">
					{mediaPortrait?.map(asset => (
						<div className="prel ohide" key={asset.contentful_id}>
							<div className="object-cover">
								<Image image={asset.large}/>
							</div>
						</div>
					))}
				</div>
			)} */}

			{/* <div className="hero-titles innerx4 page-gutters" style={{ color: 'var(--white)' }}>
				<h1 className="mb">{title}</h1>
				<Link to={internalLink} className="cta"><h2>{callToAction}</h2></Link>
			</div> */}
		</section>
	)
}

const Hero = (props) => {
	return (
		!props.copy ? (
			<HeroSplash {...props} />
		) : (
			<HeroWithCopy {...props} />
		)
	)
}

export default Hero

export const HeroFragment = graphql`
	fragment HeroModule on ContentfulHeroModule {
		__typename
		title
		titles {
			raw
		}
		contentful_id
		mediaLandscape {
			...ContentfulMediaFragment
		}
		mediaPortrait {
			...ContentfulMediaFragment
		}
		callToAction
		internalLink
		backgroundColor: backgroundColour
		color: textColour
		copy {
			raw
		}
	}
`