// Dependencies
import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import Layout from "../wrappers/Layout"
import Hero from "../components/Hero"
import ProductCollection from "../components/ProductCollection"

const Collection = ({ data, location }) => {

	const { title, contentfulCollection } = data.collection

  return (
    <Layout title={`${title} | Harrys London`} location={location}>
			<ProductCollection {...data.collection} location={location}>
				{contentfulCollection && contentfulCollection[0] && (
					<>
						<Hero {...contentfulCollection[0].hero} />
						<div className="align-center page-gutters innerx4">
							<h1>{title}</h1>
						</div>
					</>
				)}
				
			</ProductCollection>
    </Layout>
  )
}

Collection.propTypes = {
  data: PropTypes.object.isRequired
}

export default Collection

export const query = graphql`
  query($handle: String!) {
		collection: shopifyCollection(handle: { eq: $handle }) {
			title
			handle
			sortOrder
			products {
				...ProductLink
			}
			contentfulCollection {
				title
				hero {
					...HeroModule
				}
			}
		}
  }
`